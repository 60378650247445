import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import styles from '@/styles/components/footer.module.scss';
import Logo from '../ui/Logo';
import { useRouter } from 'next/router';
import { upperCase } from '@/services/utilService';
import SocialLinks from './SocialLinks';
import MainNavItems from './MainNavItems';
import NewsletterForm from '../forms/NewsletterForm';

export default function Footer() {
  const { t } = useTranslation('common');
  const router = useRouter();
  let path;

  const pathName = router.pathname;
  const arr = pathName.split('/');
  const name = arr[1] ? arr[1] : 'home';

  // path = t(name);
  path = name === 'home' ? '' : name;

  return (
    <footer
      className={`${styles.footer} ${
        name === 'store' ? styles['pad-bottom'] : ''
      }`}
    >
      <section>
        {/* <img className={styles['for-mobile']} src="/icons/logo.webp" />{' '} */}
        <img
          className={styles['for-mobile']}
          src="/icons/logo.webp"
          alt="Free Thinking logo"
        />{' '}
        <nav className={styles['nav-items']}>
          <SocialLinks />

          {/*<MainNavItems*/}
          {/*  activeClass={styles.active}*/}
          {/*  activePath={`/${path}`}*/}
          {/*  disabledClass={styles.disabled}*/}
          {/*/>*/}
        </nav>
        <nav className={styles.social}>
          {/*<SocialLinks />*/}
        </nav>
      </section>
      <hr />

      <section>
        <div>
          {/* <p dir="ltr">+972-50-4291633</p> */}
          <p>support@freethinking.org</p>
          <p className={styles.long}>{t('contact address')}</p>
          {/* <small>{t('copyright')}</small> */}
        </div>
        <img
          className={styles['for-web']}
          src="/icons/logo.webp"
          alt="Free Thinking logo"
        />{' '}
        <hr className={styles['for-mobile']} />
        <div>
          <Link href={'/terms'}>{t('site policy')}</Link>
          <Link href={'/cancelation'}>{t('cancel order')}</Link>
          <small>{t('copyright')}</small>
        </div>
      </section>
    </footer>
  );
}
