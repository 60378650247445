import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { pageNameFromPath } from '@/services/utilService';

const isBrowser = typeof window !== 'undefined';

const CustomHead = () => {
  const { t: tMeta } = useTranslation('meta');
  const { t } = useTranslation('common');
  const router = useRouter();
  const pageName = pageNameFromPath(router.pathname);
  const baseUrl = isBrowser && window.location.origin;

  function getTitle() {
    return `${tMeta('defaultTitle')}-${t(pageName)}`;
  }

  function getDesc() {
    const text = tMeta(`${pageName}Desc`);
    return text !== `${pageName}Desc` ? text : tMeta('defaultDesc');
  }

  // const fullUrl: string =
  //   (isBrowser &&
  //     `${baseUrl}${
  //       router.locale !== router.defaultLocale ? '/' + router.locale : ''
  //     }${router.asPath}`) ||
  //   `${baseUrl}${router.asPath}`;
  function ogTitle() {
    return t('siteName');
  }

  return (
    <Head>
      <meta
        property="og:image"
        content="https://freethinking.org/og-image.png"
      />
      <meta property="og:title" content={ogTitle()} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://freethinking.org" />
      <meta property="og:description" content={getDesc()} />
      <title>{getTitle()}</title>
      <meta name="description" content={getDesc()} />
      {/* <meta name="robots" content="index, follow" /> // Tell search engines to
      index the page and follow any links on the page */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="twitter:card" content="summary_large_image" /> // Set the type
      of card that appears when the page is shared on Twitter
      <meta name="twitter:title" content="Freethinking" /> // Set the title that
      appears when the page is shared on Twitter
      <meta
        name="twitter:description"
        content="The Freethinking book official website"
      />
      // Set the description that appears when the page is shared on Twitter
      <meta
        name="twitter:image"
        content="https://freethinking.org/og-image.png"
      />{' '}
      {/* {router.asPath === '/' && (
        <meta
          name="google-site-verification"
          content="WGwXj260IKS0hkojDniHDVvakm_a_WmUxVm1SvDkpD4"
        />
      )} */}
      {/* <link rel="icon" href={`${baseUrl}/favicon.ico`} />
      <link rel="icon" href={`${baseUrl}/favicon-16x16.png`} sizes="16x16" />
      <link rel="icon" href={`${baseUrl}/favicon-32x32.png`} sizes="32x32" /> */}
      <link
        rel="icon"
        href={`${baseUrl}/android-chrome-512x512.png`}
        sizes="16x16 32x32 64x64 96x96"
      />
      <link
        rel="icon"
        href={`${baseUrl}/android-chrome-192x192.png`}
        sizes="192x192"
      />
      <link
        rel="icon"
        href={`${baseUrl}/android-chrome-512x512.png`}
        sizes="512x512"
      />
      <link
        rel="apple-touch-icon"
        href={`${baseUrl}/apple-touch-icon-180x180.png`}
        sizes="180x180"
      />
      <meta
        name="msapplication-TileImage"
        content={`${baseUrl}/mstile-150x150.png`}
      />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      {/* <meta name="twitter:site" content="@myhandle" /> // Set the Twitter handle */}
    </Head>
  );
};

export default CustomHead;
