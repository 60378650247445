import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { AuthContextProvider } from '@/context/AuthContext';
import MainLayout from '@/components/layout/MainLayout';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import '@/styles/globals.scss';
import AdminLayout from '@/components/layout/AdminLayout';
import { OrderContextProvider } from '@/context/OrderContext';
import { NextPage } from 'next';
import CustomHead from '@/components/layout/CustomHead';

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID; // replace with your GTM ID
type ModifiedNextPage = NextPage & {
  layout?: string;
};

type ModifiedAppProps = AppProps & {
  Component: ModifiedNextPage;
};
const isAdminRoute = (pathname: string): boolean => {
  return pathname.includes('/admin/panel');
};

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  // const {locale} = router.locale
  const { pathname, locale } = router;
  const dir = locale === 'he' ? 'rtl' : 'ltr';
  useEffect(() => {
    document.documentElement.dir = dir;
  }, [dir]);
  return (
    <>
      <CustomHead />
      <AuthContextProvider>
        <MainLayout>
          {isAdminRoute(pathname) ? (
            <AdminLayout>
              <Component {...pageProps} />
            </AdminLayout>
          ) : (
            <OrderContextProvider>
              <Component {...pageProps} />
            </OrderContextProvider>
          )}

          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `}
          </Script>
        </MainLayout>
      </AuthContextProvider>
    </>
  );
};
export default appWithTranslation(App);
