import Link from 'next/link';
import styles from '@/styles/components/adminLayout.module.scss';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAuthContext } from '@/context/AuthContext';

const routes = [
  { path: 'analytics', name: 'Analytics' },
  { path: 'edit', name: 'Edit' },
  { path: 'add', name: 'Add' },
];

export default function AdminLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const { user }: any = useAuthContext();

  useEffect(() => {
    if (!user || user == null) router.push('/admin');
  }, []);

  return (
    <>
      {user && (
        <main
          style={{ minHeight: '70vh' }}
          className={`${styles['admin-page']}`}
        >
          <div className={styles['nav-container']}>
            <h2> {user} </h2>

            <nav>
              {/* <h2> {user} </h2> */}
              {routes.map((route) => {
                const isActive = router.asPath.includes(route.path);
                return (
                  <Link
                    key={`admin-nav-${route.path}`}
                    href={`${router.basePath}/admin/panel/${route.path}`}
                    className={isActive ? styles['active-nav-link'] : ''}
                  >
                    {route.name}
                  </Link>
                );
              })}
            </nav>
          </div>
          {/* <hr /> */}
          {children}
        </main>
      )}
    </>
  );
}
