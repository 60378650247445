import Link from 'next/link';

const fillColor = '#797979';
const socialLinks = [
  {
    name: 'twitter',
    link: 'https://twitter.com/Logic2424',
    svg: (
      <svg
        width="35"
        height="28"
        viewBox="0 0 35 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0074 28C24.2148 28 31.4389 17.2261 31.4389 7.88414C31.4389 7.57745 31.4389 7.27307 31.4179 6.96982C32.8232 5.97049 34.0359 4.72971 35.0002 3.30999C33.6912 3.88202 32.2999 4.25715 30.8771 4.42255C32.3752 3.53867 33.4969 2.15003 34.0342 0.511488C32.6237 1.33507 31.0819 1.91631 29.4736 2.22819C26.7541 -0.618174 22.2058 -0.756081 19.313 1.92142C17.4492 3.64783 16.6565 6.22189 17.2357 8.67712C11.4624 8.39111 6.08283 5.70677 2.43579 1.2908C0.530013 4.52135 1.50478 8.65261 4.66007 10.7271C3.5173 10.6943 2.39904 10.3914 1.39977 9.84352V9.93324C1.40152 13.2982 3.8113 16.1956 7.16085 16.8624C6.10383 17.1467 4.99432 17.1879 3.91981 16.9829C4.85957 19.8637 7.55635 21.8369 10.6276 21.8937C8.08486 23.8614 4.94357 24.9298 1.70953 24.9264C1.13902 24.9246 0.568513 24.8919 -0.000244141 24.8247C3.28455 26.8991 7.10485 28 11.0074 27.9949"
          fill={fillColor}
        />
      </svg>
    ),
  },
  {
    name: 'facebook',
    link: 'https://www.facebook.com/logix24',
    svg: (
      <svg
        width="13"
        height="28"
        viewBox="0 0 13 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.93297 6.66709C7.93297 6.95421 7.93297 7.89005 7.93297 9.21959H12.3399L11.8621 13.2945H7.93297C7.93297 19.5788 7.93297 28 7.93297 28H2.71774C2.71774 28 2.71774 19.6902 2.71774 13.2945H0.000244141V9.21959H2.71774C2.71774 7.60358 2.71774 6.45448 2.71774 6.13454C2.71774 4.61015 2.60702 3.88686 3.23146 2.7056C3.85623 1.52439 5.61848 -0.0369231 8.66645 0.000666019C11.7152 0.039625 13.0002 0.343845 13.0002 0.343845L12.3399 4.6867C12.3399 4.6867 10.3931 4.15349 9.43754 4.34352C8.48325 4.53361 7.93297 5.14336 7.93297 6.66709Z"
          fill={fillColor}
        />
      </svg>
    ),
  },
  {
    name: 'instagram',
    link: 'https://www.instagram.com/alon.gonen/',
    svg: (
      <svg
        width="27"
        height="27"
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1663 0H7.84765C2.9312 0 0.000244141 2.9295 0.000244141 7.8435V19.143C0.000244141 24.0705 2.9312 27 7.84765 27H19.1528C24.0692 27 27.0002 24.0705 27.0002 19.1565V7.8435C27.0137 2.9295 24.0827 0 19.1663 0ZM13.507 18.738C10.6165 18.738 8.26636 16.389 8.26636 13.5C8.26636 10.611 10.6165 8.262 13.507 8.262C16.3974 8.262 18.7476 10.611 18.7476 13.5C18.7476 16.389 16.3974 18.738 13.507 18.738ZM21.503 6.588C21.4354 6.75 21.3409 6.8985 21.2193 7.0335C21.0842 7.155 20.9357 7.2495 20.7736 7.317C20.6115 7.3845 20.4359 7.425 20.2603 7.425C19.8957 7.425 19.558 7.29 19.3014 7.0335C19.1798 6.8985 19.0853 6.75 19.0177 6.588C18.9502 6.426 18.9097 6.2505 18.9097 6.075C18.9097 5.8995 18.9502 5.724 19.0177 5.562C19.0853 5.3865 19.1798 5.2515 19.3014 5.1165C19.612 4.806 20.0848 4.6575 20.517 4.752C20.6115 4.7655 20.6926 4.7925 20.7736 4.833C20.8546 4.86 20.9357 4.9005 21.0167 4.9545C21.0842 4.995 21.1518 5.0625 21.2193 5.1165C21.3409 5.2515 21.4354 5.3865 21.503 5.562C21.5705 5.724 21.611 5.8995 21.611 6.075C21.611 6.2505 21.5705 6.426 21.503 6.588Z"
          fill={fillColor}
        />
      </svg>
    ),
  },
  // {
  //   name: 'linkedin',
  //   link: 'https://www.linkedin.com/in/alongonen24/',
  //   svg: (
  //     <svg
  //       width="29"
  //       height="28"
  //       viewBox="0 0 29 28"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M6.58341 27.9999V9.10751H0.367466V27.9999H6.58341V27.9999ZM3.47609 6.52902C5.64304 6.52902 6.99287 5.07708 6.99287 3.26416C6.9519 1.41095 5.64304 0 3.51673 0C1.39007 5.73254e-05 0 1.41101 0 3.26422C0 5.07714 1.3491 6.52907 3.43517 6.52907L3.47609 6.52902ZM10.0237 27.9999C10.0237 27.9999 10.1052 10.8801 10.0237 9.10757H16.2406V11.8474H16.1993C17.0168 10.5581 18.4895 8.66393 21.8427 8.66393C25.9337 8.66393 29 11.3641 29 17.1672V28H22.7841V17.8931C22.7841 15.3535 21.8847 13.6206 19.6346 13.6206C17.9176 13.6206 16.8944 14.7889 16.445 15.9182C16.2806 16.3201 16.2406 16.8847 16.2406 17.4494V27.9999H10.0237Z"
  //         fill={fillColor}
  //       />
  //     </svg>
  //   ),
  // },
];

const SocialLinks = () => {
  return (
    <>
      {socialLinks.map((item) => {
        return (
          <Link key={`follow-${item.name}`} href={item.link}>
            {item.svg}
          </Link>
        );
      })}
    </>
  );
};

export default SocialLinks;
