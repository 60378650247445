import { getName } from 'i18n-iso-countries';
import type { NextApiRequest } from 'next';
import Cookies from 'universal-cookie';

export function setJqueryScripts() {
  // debugger;
  if (!document.getElementById('script1')) {
    const script1 = document.createElement('script');
    script1.src = `https://code.jquery.com/jquery-3.6.0.js`;
    script1.id = 'script1';

    script1.onload = () => {
      if (!document.getElementById('script2')) {
        const script2 = document.createElement('script');
        script2.src = `https://direct.tranzila.com/js/tranzilanapple_v3.js?v=${Date.now()}`;
        script2.id = 'script2';

        script2.onload = () => {
          if (!document.getElementById('script3')) {
            const script3 = document.createElement('script');
            script3.text = 'var $n = jQuery.noConflict(true);';
            script3.id = 'script3';

            if (document?.body) {
              document.body.appendChild(script3);
            }
          }
        };

        if (document?.body) {
          document.body.appendChild(script2);
        }
      }
    };

    if (document?.body) {
      document.body.appendChild(script1);
    }
  }

  return () => {
    if (document?.body) {
      ['script1', 'script2', 'script3'].forEach((id) => {
        const script = document.getElementById(id);
        if (script && document.body.contains(script)) {
          document.body.removeChild(script);
        }
      });
    }
  };
}

export function upperCase(str: string): string {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function pluralToSingular(plural: string): string {
  if (plural.toLowerCase().endsWith('s')) {
    return plural.slice(0, -1);
  }

  return plural;
}

export function fullLangName(name: string) {
  switch (name) {
    case 'he':
      return 'Hebrew';

    case 'en':
      return 'English';
    default:
      break;
  }
}

export function generateId() {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';

  for (let i = 0; i < 20; i++) {
    id += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return id;
}

export function pageNameFromPath(pathName: string) {
  // const pathName = router.pathname;
  const arr = pathName.split('/');
  return arr[1] ? arr[1] : 'home';
}

export function getTime(timestamp: number, languageCode: string) {
  const date = new Date(timestamp);
  const localeCode = languageCode === 'he' ? 'he-IL' : 'en-US';

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: localeCode === 'en-US',
  };

  const formattedTime = new Intl.DateTimeFormat(localeCode, options).format(
    date
  );
  return formattedTime;
}
/**
 * Get client IP address from request.
 *
 * @param req
 */
const getClientIpAddress = (req: NextApiRequest): string => {
  const ipAddress = req.headers['x-real-ip'] || req.connection.remoteAddress;

  if (ipAddress) {
    return String(ipAddress);
  }

  const xForwardedFor = (req.headers['x-forwarded-for'] as string) ?? '';

  return xForwardedFor.split(',')[0];
};

/**
 * Get client user agent from request.
 *
 * @param req
 */
const getClientUserAgent = (req: NextApiRequest): string =>
  String(req.headers['user-agent'] ?? '');

/**
 * Get client fbp from request cookie.
 *
 * @param req
 */
const getClientFbp = (req: NextApiRequest): string => {
  const cookies = new Cookies(req.headers.cookie);

  if (!cookies.get('_fbp')) {
    return '';
  }

  return cookies.get('_fbp');
};

/**
 * Get client fbc from request query params or cookie.
 *
 * @param req
 */
const getClientFbc = (req: NextApiRequest): string => {
  if (req.headers.referer) {
    const url = new URL(req.headers.referer);

    if (url.searchParams.has('fbclid')) {
      return url.searchParams.get('fbclid') ?? '';
    }
  }

  const cookies = new Cookies(req.headers.cookie);

  if (cookies.get('_fbc')) {
    return cookies.get('_fbc');
  }

  return '';
};

const getClientTikTokValue = (req: NextApiRequest, paramName: string, cookieName: string) => {
  // Check the referer's URL parameters
  if (req.headers.referer) {
    const url = new URL(req.headers.referer);

    if (url.searchParams.has(paramName)) {
      return url.searchParams.get(paramName) ?? '';
    }
  }

  // Check the cookies
  const cookies = new Cookies(req.headers.cookie);
  if (cookies.get(cookieName)) {
    return cookies.get(cookieName);
  }

  return '';
};

export { getClientIpAddress, getClientUserAgent, getClientFbp, getClientFbc,getClientTikTokValue };
