import {bookService} from '@/firebase/bookService';
import {useRouter} from 'next/router';
import React, {useContext, useEffect, useState} from 'react';

import firebase_app from '@/firebase/config';
import {getFirestore, collection, doc, onSnapshot} from 'firebase/firestore';
// import { emailService } from '@/services/emailService';
// import { orderService } from '@/firebase/orderService';
// import { set } from 'date-fns';
// import { facebookService } from '@/services/facebookService';
const db = getFirestore(firebase_app);

const defaultFormat = 'softcover'; //Needs to always be a format which is available, otherwise nothing is rendered
const defaultCurrency = 'NIS';

interface Format {
    // priceUSD: number;
}

interface ShippingInfo {
    fullName: string;
    email: string;
    phone: string;
    country: string;
    city: string;
    // address?: string;
    postalCode?: string;
    street: string;
    number: string;
    apartment: string;
    deliveryNotes?: string;
}

const OrderContext = React.createContext<{
    availableFormats: any;
    currFormatName: string;
    currFormat: any;
    currAmount: number;
    amountInStock: number;
    totalPrice: number;
    discount: number;
    totalDiscount: number;
    originalUnitPrice: number;
    listPrice: number;
    selectedCurrency: string;
    shippingInfo: ShippingInfo | null;
    orderId: string;
    paymentStatus: string;
    coupons?: any;
    currCoupon?: any;
    selectedPaymentMethod: string;
    extraContent: boolean;
    extraAudioBook: boolean;
    extraContentPrice: number;
    extraAudioBookPrice: number;

    allowSubscription: boolean;
}>({
    availableFormats: null,
    currFormatName: defaultFormat,
    currFormat: null,
    currAmount: 1,
    amountInStock: 0,
    totalPrice: 20,
    discount: 0,
    totalDiscount: 0,
    originalUnitPrice: 20,
    listPrice: 20,
    selectedCurrency: defaultCurrency,
    shippingInfo: null,
    orderId: '',
    paymentStatus: '',
    coupons: [],
    currCoupon: {},
    selectedPaymentMethod: 'card',
    extraContentPrice: 14,
    extraAudioBookPrice: 50,
    extraContent: false,
    extraAudioBook: false,
    allowSubscription: false,
});

const UpdateOrderContext = React.createContext<{
    updateSelectedFormat: (formatName: string) => void;
    updateAmount: (amount: number) => void;
    updateShippingInfo: (info: ShippingInfo) => void;
    updateOrderId: (id: string) => void;
    updateCouponDiscount: (code: string) => string | null;
    clearContext: () => void;
    clearPaymentContext: () => void;
    updatePaymentMethod: (method: string) => void;
    updateExtraContent: (method: boolean) => void;
    updateExtraAudioBook: (method: boolean) => void;
    updateAllowSubscription: (value: boolean) => void;
}>({
    updateSelectedFormat: () => {
    },
    updateAmount: () => {
    },
    updateShippingInfo: () => {
    },
    updateOrderId: () => {
    },
    clearContext: () => {
    },
    clearPaymentContext: () => {
    },
    updateCouponDiscount: () => '',
    updatePaymentMethod: () => {
    },
    updateExtraContent: () => {
    },
    updateExtraAudioBook: () => {
    },
    updateAllowSubscription: () => {
    },
});

export function useOrderContext() {
    // debugger;
    return useContext(OrderContext);
}

export function useUpdateOrderContext() {
    return useContext(UpdateOrderContext);
}

export const OrderContextProvider = ({children}: any) => {
    const router = useRouter();
    const [currAmount, setCurrAmount] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const [originalUnitPrice, setOriginalUnitPrice] = useState(0); //original price is the price without discount for a unit
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [listPrice, setListPrice] = useState(0); //list price is the price without discount multiplied by amount
    const [discount, setDiscount] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);

    const [amountInStock, setAmountInStock] = useState(0);
    const [availableFormats, setAvailableFormats] = useState<any>(null);

    const [currFormatName, setCurrFormatName] = useState(defaultFormat);
    const [currFormat, setCurrFormat] = useState<Format | null>(null);

    const [shippingInfo, setShippingInfo] = useState<ShippingInfo | null>(null);
    const [orderId, setOrderId] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [currCoupon, setCurrCoupon] = useState<any>(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('card');
    const [extraContent, setExtraContent] = useState(false);
    const [extraAudioBook, setExtraAudioBook] = useState(false);

    const [extraContentPrice, setExtraContentPrice] = useState(14);
    const [extraAudioBookPrice, setExtraAudioBookPrice] = useState(50);
    const [allowSubscription, setAllowSubscription] = useState(false);

    useEffect(() => {
        fetchAndSetFormats();
    }, []);

    // TO DO WHEN CURRENCY
    // useEffect(() => {
    //   const currency = currencyService.getCurrencyByLang(router.locale);
    //   setSelectedCurrency(currency);
    //   //update totalPrice too
    //   setPrices(currFormat, currAmount);
    // }, [router.locale]);

    // #####
    useEffect(() => {
        if (!orderId) return;
        const collectionRef = collection(db, 'orders');
        const docRef = doc(collectionRef, orderId);

        const unsubscribe = onSnapshot(docRef, async (snapshot) => {
            const data = snapshot.data();
            if (data && data.paymentStatus) {
                if (data.paymentStatus === 'confirmed') {
                    console.log('payment confirmed');
                    //send email
                    const lang = router.locale;
                    // await orderService.saveConfirmedPaymentInfo(
                    //   data,
                    //   orderId
                    // );
                    // await emailService.sendPurchaseEmail(data, lang);
                    // await orderService.saveOrdersToSheets();
                    // await facebookService.sendPurchaseEvent(data);
                }
                setPaymentStatus(data.paymentStatus);
            }
        });
        return () => unsubscribe();
    }, [orderId]);

    function setPrices(
        format: any,
        amount: number,
        couponValue: number = 0,
        check: boolean = false
        , checkAudioBook: boolean = false
    ) {
        console.log('called set prices');

        //TO DO WHEN CURRENCY
        // const newPrice = bookService.getPrice(router, format);
        // const newDiscount =
        //   bookService.getDiscount(router, format) + couponValue * newPrice;

        const newPrice = format[`price${selectedCurrency}`];
        const newDiscount =
            format[`discount${selectedCurrency}`] + couponValue * newPrice;

        setOriginalUnitPrice(newPrice);
        setListPrice(newPrice * amount);
        setDiscount(newDiscount);
        setTotalDiscount(newDiscount * amount);
        if (check) {
            setTotalPrice((newPrice - newDiscount) * amount + extraContentPrice);
        } else if (checkAudioBook) {
            setTotalPrice((newPrice - newDiscount) * amount + extraAudioBookPrice);
        } else {
            setTotalPrice((newPrice - newDiscount) * amount);
        }
    }

    async function fetchAndSetFormats() {
        try {
            const formats = await bookService.getBookFormats();
            // const arrayOfObjects = Object.entries(formats).map(([key, value]) => ({
            //   [key]: value,
            // }));

            for (const key in formats) {
                const format = formats[key];
                let lang;
                if (format.languages.length > 1) {
                    lang = format.languages.includes(router.locale)
                        ? router.locale
                        : router.defaultLocale;
                } else {
                    lang = format.languages[0];
                }
                format.selectedLang = lang;
            }
            // debugger;
            setAvailableFormats(formats);
            setCurrFormat(formats[currFormatName]);
            setPrices(formats[currFormatName], currAmount);
            if (formats[currFormatName]?.hasOwnProperty('inStock')) {
                setAmountInStock(
                    formats[currFormatName].inStock[formats[currFormatName].selectedLang]
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    // function updateLang(lang: string) {
    //   setCurrLang(lang);
    // }
    function updatePaymentMethod(method: string) {
        setSelectedPaymentMethod(method);
    }

    function updateAmount(amount: number) {
        if (currFormat) {
            setCurrAmount(amount);
            setPrices(currFormat, amount);
        }
    }

    function updateExtraAudioBook(check: boolean) {
        // debugger;
        setExtraAudioBook(check);
        setPrices(
            availableFormats[currFormatName],
            currAmount,
            currCoupon?.value,
            false,
            check
        );
    }

    function updateExtraContent(check: boolean) {
        setExtraContent(check);
        setPrices(
            availableFormats[currFormatName],
            currAmount,
            currCoupon?.value,
            check
        );
    }

    function updateSelectedFormat(formatName: string) {
        if (availableFormats && !availableFormats[formatName].available) return;

        setCurrFormatName(formatName);
        if (availableFormats) {
            const format = availableFormats[formatName];
            setCurrFormat(format);
            setCurrAmount(1);
            setPrices(format, 1);
            if (format.hasOwnProperty('inStock')) {
                const lang = format.selectedLang;
                setAmountInStock(format.inStock[lang]);
            }
        }
    }

    function updateShippingInfo(info: ShippingInfo) {
        setShippingInfo(info);
    }

    function updateOrderId(id: string) {
        setOrderId(id);
    }

    function clearContext() {
        console.log('clearing context');
        setCurrAmount(1);
        setPrices(availableFormats[defaultFormat], 1);
        setCurrFormatName(defaultFormat);
        setCurrFormat(availableFormats[defaultFormat]);
        setShippingInfo(null);
        setOrderId('');
        setPaymentStatus('');
    }

    function clearPaymentContext() {
        console.log('clearing payment context');
        setOrderId('');
        setPaymentStatus('');
    }

    function updateCouponDiscount(code: string) {
        //find the coupon code within the format, according to coupon.code
        const format = availableFormats[currFormatName];
        const coupon = format.coupons.find((c: any) => c.code === code);
        if (!coupon) return null;
        setCurrCoupon(coupon);
        setCouponDiscount(coupon.discount);
        setPrices(currFormat, currAmount, coupon.value);
        return 'success';
    }

    function updateAllowSubscription(value: boolean) {
        setAllowSubscription(value);
    }

    return (
        <OrderContext.Provider
            value={{
                availableFormats,
                currFormat,
                currFormatName,
                currAmount,
                amountInStock,
                totalPrice,
                discount,
                totalDiscount,
                originalUnitPrice,
                listPrice,
                selectedCurrency,
                shippingInfo,
                orderId,
                paymentStatus,
                selectedPaymentMethod,
                extraContent,
                extraAudioBook,
                extraAudioBookPrice,
                extraContentPrice,
                allowSubscription,
            }}
        >
            <UpdateOrderContext.Provider
                value={{
                    updateSelectedFormat,
                    updateAmount,
                    updateShippingInfo,
                    updateOrderId,
                    clearContext,
                    clearPaymentContext,
                    updateCouponDiscount,
                    updatePaymentMethod,
                    updateExtraContent,
                    updateExtraAudioBook,
                    updateAllowSubscription,
                }}
            >
                {children}
            </UpdateOrderContext.Provider>
        </OrderContext.Provider>
    );
};
