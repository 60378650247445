import firebase_app from './config';

import {
  getFirestore,
  getDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
  Timestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore/lite';

export const bookService = {
  getMetaDetails,
  getTransDetails,
  getReviews,
  getBookFormats,
  getBookAmount,
  updateBookAmount,
  // getPrice,
  // getDiscount,
};

const db = getFirestore(firebase_app);

async function getMetaDetails() {
  const collectionName = 'book';
  const docName = 'details';
  const docSnap = await getDoc(doc(db, collectionName, docName));

  const data = docSnap.exists() ? docSnap.data() : null;

  if (data && data.releaseDate) {
    data.releaseDate = data.releaseDate.toDate().getTime();
  }

  return data;
}
async function getBookAmount() {
  const collectionName = 'book';
  const docName = 'details';
  const docSnap = await getDoc(doc(db, collectionName, docName));

  const data = docSnap.exists() ? docSnap.data().formats.softcover.inStock.he : null;

  return data;
}

async function updateBookAmount(format: any, amountPurchased: number, formatName: string) {
  //get previous amount and subtract 1
  const previousAmount = await getBookAmount();
  const amount = previousAmount - amountPurchased;

  const collectionName = 'book';
  const docName = 'details';
  const docRef = doc(db, collectionName, docName);

  try {
    const updatePath = `formats.${formatName}.inStock.${format.selectedLang}`;
    console.log("updatePath", updatePath)
    const res = await updateDoc(docRef, { [updatePath]: amount });
    console.log("res", res);
    
    return { message: 'Saved successfully' };
  } catch (error) {
    return { message: 'Error updating' };
  }
}

async function getBookFormats() {
  const collectionName = 'book';
  const docName = 'details';
  const docSnap = await getDoc(doc(db, collectionName, docName));

  const data = docSnap.exists() ? docSnap.data().formats : null;

  return data;
}

async function getTransDetails(collectionName: any) {
  const docName = 'book';
  const docSnap = await getDoc(doc(db, collectionName, docName));
  return docSnap.exists() ? docSnap.data() : null;
}

// async function getReviews(collectionName: any) {
//   const docName = 'book';
//   const subCollectionName = 'reviews';

//   const ref = collection(db, collectionName, docName, subCollectionName);
//   const docsSnap = await getDocs(ref);
//   return docsSnap.docs.map((doc) => ({
//     id: doc.id,
//     ...doc.data(),
//     createdAt: doc.data().createdAt?.toDate().getTime(),
//   }));
// }

async function getReviews(lang: any) {
  const metaReviewsDocSnap = await getDoc(doc(db, 'book', 'reviews'));

  if (metaReviewsDocSnap.exists()) {
    const reviewMetaData = metaReviewsDocSnap.data();
    const reviewArray = Object.entries(reviewMetaData).map(([id, data]) => ({
      id,
      ...data,
    }));

    const reviews = await Promise.all(
      reviewArray.map(async (review) => {
        review.createdAt = review.createdAt?.toDate()?.getTime() || null;
        review.id = review.id.trim();
        const transData = await getReviewTransData(lang, review.id);
        if (!transData) return {};
        return {
          ...transData,
          ...review,
        };
      })
    );
    return reviews;
  }
}

async function getReviewTransData(collectionName: any, id: string) {
  const docName = 'book';
  const subCollectionName = 'reviews';

  const docRef = doc(db, collectionName, docName, subCollectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }
}
//TODO WHEN CURRENCY
// function getPrice(router: any, format: any) {
//   const price: number = router.locale === 'en' ? format?.priceUSD : format?.priceNIS;
//   return price;

// }

// function getDiscount(router: any, format: any) {
//   const discount: number = router.locale === 'en' ? format?.discountUSD : format?.discountNIS;
//   return discount;
// }

//WITH NESTED SERIALIZED DATES
// async function getMetaDetails() {
//   const collectionName = 'book';
//   const docName = 'details';
//   const docSnap = await getDoc(doc(db, collectionName, docName));
//   if (docSnap.exists()) {
//     const data = docSnap.data();
//     const serializedData = serializeTimestamps(data);
//     return serializedData;
//   }
//   return null;
// }

// function serializeTimestamps(data) {
//   const serializedData = {};
//   for (const key in data) {
//     if (data.hasOwnProperty(key)) {
//       const value = data[key];
//       if (value && typeof value === 'object' && value.hasOwnProperty('toDate')) {
//         serializedData[key] = value.toDate().getTime();
//       } else if (typeof value === 'object') {
//         serializedData[key] = serializeTimestamps(value);
//       } else {
//         serializedData[key] = value;
//       }
//     }
//   }
//   return serializedData;
// }
