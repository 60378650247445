import { useEffect } from 'react';
import { ToastNotification } from '../ui/Toast';
import Footer from './Footer';
import MainHeader from './MainHeader';
import LimitedTime from './LimitedTime';
// import { Inter } from 'next/font/google';

// const inter = Inter({ subsets: ['latin'] });

export default function MainLayout(props: any) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/*<ToastNotification />*/}
      {/* <LimitedTime/> */}
      {/*<MainHeader />*/}
      <>{props.children}</>
      <Footer />
    </>
  );
}
