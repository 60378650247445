
import styles from '@/styles/components/loader.module.scss';

const Loader = () => {

  return (
    <div className={styles['loader-container']}>
      {/* {msg} */}
      <div className={styles['lds-roller']}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
